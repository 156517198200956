<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" @click="refreshData">
            <v-icon dense>mdi-refresh</v-icon>
            {{ $t("refresh") }}
          </v-btn>
          <v-switch
            v-model="includeDone"
            :label="$t('includeDone')"
            dense
          ></v-switch>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :sort-by="[]"
      :sort-desc="[]"
      dense
      multi-sort
    >
      <template v-slot:item.lastStatusCode="{ item }">
        <v-chip
          v-if="item.lastStatusCode"
          :color="getStatusColor(item.lastStatusCode)"
          dark
          label
        >
          {{ getRequestStatusText(item.lastStatusCode) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="isDeletable(item)"
          small
          class="mr-2"
          @click="onDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { getRequestStatusText } from "@/utils/request";

export default {
  i18n: {
    messages: {
      en: {
        includeDone: "Include Done",
      },
      ko: {
        includeDone: "완료 포함",
      },
    },
  },
  data: (vm) => ({
    itemsPerPage: 10,
    headers: [
      {
        text: vm.$i18n.t("request.requestId"),
        value: "id",
      },
      {
        text: vm.$i18n.t("request.action"),
        value: "action",
      },
      {
        text: vm.$i18n.t("request.ip"),
        value: "ip",
      },
      {
        text: vm.$i18n.t("request.statusCode"),
        value: "lastStatusCode",
      },
      {
        text: vm.$i18n.t("request.requestAt"),
        value: "createdAt",
      },
      {
        text: "-",
        value: "actions",
        sortable: false,
      },
    ],
    items: [],
    includeDone: false,
    snackbar: false,
    notificationMessage: "",
  }),
  mounted() {
    this.refreshData();
  },
  methods: {
    async refreshData() {
      try {
        const res = await this.authGet(`/api/request?done=${this.includeDone}`);
        this.items = res.data.data;
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    getStatusColor(status) {
      switch (status) {
        case "0":
          return "blue";
        case "APPROVED":
          return "green";
        case "REJECTED":
          return "purple";
      }
      return status ? "red" : undefined;
    },

    getRequestStatusText(status) {
      return getRequestStatusText(status);
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },

    /**
     * 요청 삭제
     */
    async onDelete(item) {
      const requestId = item.id;
      try {
        // TODO: 삭제 확인 필요

        // 삭제 요청
        await this.authDelete(`/api/request/${requestId}`);

        // 삭제 성공시 목록에서 제거
        const idx = this.items.findIndex((it) => it.id === requestId);
        if (idx >= 0) {
          this.items.splice(idx, 1);
        }
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    isDeletable(req) {
      const { lastStatusCode } = req;
      return lastStatusCode == null || lastStatusCode === "";
    },
  },
};
</script>
